/* eslint no-console:0 */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb

// Uncomment to copy all static images under ./images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('./images', true)
// const imagePath = (name) => images(name, true)

import React from "react";
import { createRoot } from "react-dom/client";
import { Provider } from "react-redux";
import { ConfigProvider, theme } from "antd";
import Routes from "Routes";
import { ToastContainer } from "react-toastify";
import * as Sentry from "@sentry/react";
import store from "./store";
import posthog from "posthog-js";

import "react-toastify/dist/ReactToastify.css";

const container = document.getElementById("root");
const root = createRoot(container);

Sentry.init({
  dsn: "https://d7171c1e895240fda4ae684817b3ecd9@o4508305113481216.ingest.us.sentry.io/4508305113612288",
  integrations: [new Sentry.BrowserTracing()],
  tracesSampleRate: 1.0,
  enabled: process.env.NODE_ENV === "production",
});

posthog.init("phc_pNj8QG47GlQyPRBsHB8tF0IFq6NIeekEgkP7B7ha7c0", {
  api_host: "https://us.i.posthog.com",
  person_profiles: "identified_only", // or 'always' to create profiles for anonymous users as well
});

document.addEventListener("DOMContentLoaded", () => {
  root.render(
    <Provider store={store}>
      <ConfigProvider
        theme={{
          algorithm: theme.defaultAlgorithm,
        }}
      >
        <Routes />
        <ToastContainer
          toastStyle={{
            backgroundColor: "var(--secondary-color)",
            color: "var(--default-font-color)",
            border: "1px solid var(--accent-color)",
            boxShadow: "0px 4px 10px var(--active-background-color)",
          }}
        />
      </ConfigProvider>
    </Provider>
  );
});
