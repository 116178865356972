import { configureStore } from "@reduxjs/toolkit";
import userReducer from "./slices/userSlice";
import billingReducer from "./slices/billingSlice";
import modalsReducer from "./slices/modalSlice";
import imagesReducer from "./slices/imagesSlice";
import journalReducer from "./journal/journalSlice";
import quotesReducer from "quotes/quotesSlice";
import gratitudeReducer from "./gratitude/gratitudeSlice";
import visionReducer from "./vision/visionSlice";
import notificationsReducer from "./slices/notificationsSlice";

const store = configureStore({
  reducer: {
    user: userReducer,
    billing: billingReducer,
    modals: modalsReducer,
    images: imagesReducer,
    journal: journalReducer,
    quotes: quotesReducer,
    gratitude: gratitudeReducer,
    vision: visionReducer,
    notifications: notificationsReducer,
  },
});

export default store;
